// Admin.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  Typography,
  Box,
  Container,
  CircularProgress,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  List,
  ListItem,
  ListItemText,
  Divider,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

function Admin() {
  // Существующие хуки состояния
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [deleteUsername, setDeleteUsername] = useState('');
  const [users, setUsers] = useState([]);

  // Новые хуки состояния
  const [newPassword, setNewPassword] = useState('');
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUserForPassword, setSelectedUserForPassword] = useState('');
  const [selectedUserForSticker, setSelectedUserForSticker] = useState('');
  const [stickerText, setStickerText] = useState('');

  // Новые хуки для регистрации пользователя с полями name, login и password
  const [botName, setBotName] = useState('');
  const [botLogin, setBotLogin] = useState('');
  const [botPassword, setBotPassword] = useState('');
  const [selectedUserToDelete, setSelectedUserToDelete] = useState('');
  const navigate = useNavigate();

  // Функция для получения всех пользователей и их стикеров
  const fetchAllUsers = async () => {
    try {
      const response = await axios.get('https://mapyg.ru/bot/users?timestamp=${new Date().getTime()}');
      console.log('Полученные данные пользователей:', response.data);
      setAllUsers(response.data);
    } catch (error) {
      console.error('Ошибка при получении всех пользователей:', error);
      setError('Не удалось загрузить пользователей.');
    }
  };

  // Функция для получения списка пользователей (существующая)
  const fetchUsers = async () => {
    try {
      const response = await axios.get('https://mapyg.ru/server/users', { withCredentials: true });
      setUsers(response.data);
    } catch (error) {
      console.error('Ошибка при получении пользователей:', error);
      setError('Не удалось загрузить пользователей.');
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchAllUsers();
  }, []);

  // Функция для создания пользователя (существующая)
  const handleCreateUser = async () => {
    setLoading(true);
    setError(null);
    setMessage(null);

    try {
      const response = await axios.post('https://mapyg.ru/server/register', { username, password, isAdmin });
      setMessage(response.data);
      alert('Пользователь создан!');
      fetchUsers();
    } catch (error) {
      console.error('Ошибка при создании пользователя', error);
      if (error.response && error.response.status === 400) {
        setError('Пользователь уже существует.');
      } else {
        setError('Ошибка при создании пользователя. Пожалуйста, попробуйте еще раз.');
      }
    } finally {
      setLoading(false);
    }
  };

  // Функция для регистрации пользователя в bot.js с полями name, login и password
  const handleBotRegister = async () => {
    setLoading(true);
    setError(null);
    setMessage(null);

    // Проверка на заполненность полей
    if (!botName || !botLogin || !botPassword) {
      setError('Пожалуйста, заполните все поля для регистрации.');
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post('https://mapyg.ru/bot/register?timestamp=${new Date().getTime()}', {
        name: botName,
        login: botLogin,
        password: botPassword,
      });
      setMessage('Пользователь успешно зарегистрирован.');
      setBotName(''); // Очистка полей после регистрации
      setBotLogin('');
      setBotPassword('');
      fetchAllUsers(); // Обновляем список пользователей
    } catch (error) {
      console.error('Ошибка при регистрации пользователя', error);
      setError('Ошибка при регистрации пользователя. Пожалуйста, попробуйте еще раз.');
    } finally {
      setLoading(false);
    }
  };

  // Функция для смены пароля выбранного пользователя
  const handleChangePassword = async () => {
    setLoading(true);
    setError(null);
    setMessage(null);

    if (!selectedUserForPassword || !newPassword) {
      setError('Пожалуйста, выберите пользователя и введите новый пароль.');
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post('https://mapyg.ru/bot/admin/change-password', {
        login: selectedUserForPassword,
        newPassword,
      });
      setMessage(`Пароль пользователя ${selectedUserForPassword} успешно изменен.`);
      setNewPassword(''); // Очистка поля после изменения
    } catch (error) {
      console.error('Ошибка при смене пароля', error);
      setError('Ошибка при смене пароля. Пожалуйста, попробуйте еще раз.');
    } finally {
      setLoading(false);
    }
  };

  // Функция для добавления стикера выбранному пользователю
  const handleAddSticker = async () => {
    setLoading(true);
    setError(null);
    setMessage(null);

    if (!selectedUserForSticker || !stickerText) {
      setError('Пожалуйста, выберите пользователя и введите текст стикера.');
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post('https://mapyg.ru/bot/admin/add-sticker', {
        login: selectedUserForSticker,
        text: stickerText,
      });
      setMessage('Стикер успешно добавлен.');
      setStickerText(''); // Очистка поля после добавления
      fetchAllUsers(); // Обновляем список пользователей
    } catch (error) {
      console.error('Ошибка при добавлении стикера', error);
      setError('Ошибка при добавлении стикера. Пожалуйста, попробуйте еще раз.');
    } finally {
      setLoading(false);
    }
  };

  // Функция для удаления стикера выбранного пользователя
  const handleDeleteSticker = async (stickerId) => {
    setLoading(true);
    setError(null);
    setMessage(null);

    try {
      const response = await axios.delete(`https://mapyg.ru/bot/admin/delete-sticker/${stickerId}`);
      setMessage('Стикер успешно удален.');
      fetchAllUsers(); // Обновляем список пользователей
    } catch (error) {
      console.error('Ошибка при удалении стикера', error);
      setError('Ошибка при удалении стикера. Пожалуйста, попробуйте еще раз.');
    } finally {
      setLoading(false);
    }
  };

 // Функция для удаления бот-пользователя
  const handleDeleteBotUser = async () => {
    setLoading(true);
    setError(null);
    setMessage(null);

    if (!selectedUserToDelete) {
      setError('Пожалуйста, выберите пользователя для удаления.');
      setLoading(false);
      return;
    }

    try {
      const response = await axios.delete(
        `https://mapyg.ru/bot/user/${selectedUserToDelete}?timestamp=${new Date().getTime()}`,
        {
          headers: {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            Pragma: 'no-cache',
            Expires: '0',
          },
        }
      );
      setMessage(`Пользователь ${selectedUserToDelete} успешно удален.`);
      setSelectedUserToDelete('');
      fetchAllUsers(); // Обновляем список пользователей
    } catch (error) {
      console.error('Ошибка при удалении пользователя', error);
      if (error.response) {
        console.error('Ответ сервера:', error.response.data);
        setError(`Ошибка: ${error.response.data.message}`);
      } else {
        setError('Ошибка при удалении пользователя. Пожалуйста, попробуйте еще раз.');
      }
    } finally {
      setLoading(false);
    }
  };

  // Функция для удаления пользователя (существующая)
  const handleDeleteUser = async () => {
    setLoading(true);
    setError(null);
    setMessage(null);

    if (deleteUsername === 'Admin') {
      setError('Пользователь "Admin" не может быть удален.');
      setLoading(false);
      return;
    }

    try {
      const response = await axios.delete(`https://mapyg.ru/server/users/${deleteUsername}`);
      setMessage(`Пользователь ${deleteUsername} успешно удален`);
      fetchUsers();
    } catch (error) {
      console.error('Ошибка при удалении пользователя', error);
      setError('Ошибка при удалении пользователя. Пожалуйста, попробуйте снова.');
    } finally {
      setLoading(false);
    }
  };

  // Остальные существующие функции
  const handleFetchData = async () => {
    setLoading(true);
    setError(null);
    setMessage(null);

    try {
      const response = await axios.get('https://mapyg.ru/api/update-markers', { withCredentials: true });
      if (response.status === 200) {
        setMessage(response.data);
      } else {
        setError('Неожиданный статус ответа. Пожалуйста, попробуйте снова.');
      }
    } catch (error) {
      console.error('Ошибка при получении данных:', error);
      if (error.response) {
        if (error.response.status === 200) {
          setMessage('Обновление прошло успешно, но возникли некоторые проблемы с сетевым соединением.');
        } else {
          setError(`Ошибка при получении данных: ${error.response.data}. Пожалуйста, попробуйте снова.`);
        }
      } else {
        setError('Ошибка сети. Пожалуйста, проверьте подключение.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      await axios.post('https://mapyg.ru/server/logout', {}, { withCredentials: true });
      navigate('/login');
    } catch (error) {
      console.error('Ошибка выхода:', error);
      setError('Ошибка выхода. Пожалуйста, попробуйте снова.');
    }
  };

  const handleGoToMap = () => {
    navigate('/map');
  };

  return (
    <Container component="main" maxWidth="md">
      {/* Существующая разметка */}
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h2">
          MapYG
        </Typography>
        <Typography component="h1" variant="h5">
          Панель Админа
        </Typography>
        <Box component="form" noValidate sx={{ mt: 1 }}>
          {/* Поля для создания пользователя */}
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Имя пользователя"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Пароль"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <FormControlLabel
            control={<Checkbox checked={isAdmin} onChange={(e) => setIsAdmin(e.target.checked)} color="primary" />}
            label="Пользователь Админ?"
          />
          <Button
            type="button"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleCreateUser}
          >
            Создать пользователя
          </Button>

          {/* Выпадающий список пользователей для удаления */}
          <FormControl fullWidth sx={{ mt: 3, mb: 2 }}>
            <InputLabel id="user-select-label-delete">Список пользователей</InputLabel>
            <Select
              labelId="user-select-label-delete"
              id="user-select-delete"
              value={deleteUsername}
              onChange={(e) => setDeleteUsername(e.target.value)}
            >
              {Array.isArray(users) && users.length > 0 ? (
                users.map((user) => (
                  <MenuItem key={user.username} value={user.username}>
                    {user.username} - {user.isAdmin ? 'Админ' : 'Пользователь'}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>Нет доступных пользователей</MenuItem>
              )}
            </Select>
          </FormControl>

          {/* Кнопки для удаления пользователя и других действий */}
          <Button
            type="button"
            fullWidth
            variant="outlined"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleDeleteUser}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Удалить пользователя'}
          </Button>
          <Button
            type="button"
            fullWidth
            variant="outlined"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleFetchData}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Сделать обновление базы'}
          </Button>
          <Button
            type="button"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleGoToMap}
          >
            Карта
          </Button>
          {message && <Typography color="success.main">{message}</Typography>}
          {error && <Typography color="error">{error}</Typography>}
          <Button
            type="button"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleLogout}
          >
            Выйти
          </Button>
        </Box>
      </Box>

      {/* Вторая часть страницы */}
      <Box
        sx={{
          marginTop: 8,
        }}
      >
        <Typography component="h2" variant="h5">
          Управление пользователями Бота
        </Typography>

        {/* Регистрация пользователя (bot.js) */}
        <Box component="form" noValidate sx={{ mt: 1 }}>
          <Typography component="h3" variant="h6">
            Регистрация пользователя
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="bot-name"
            label="Имя пользователя"
            name="bot-name"
            autoComplete="name"
            value={botName}
            onChange={(e) => setBotName(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="bot-login"
            label="Логин"
            name="bot-login"
            autoComplete="username"
            value={botLogin}
            onChange={(e) => setBotLogin(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="bot-password"
            label="Пароль"
            type="password"
            id="bot-password"
            autoComplete="new-password"
            value={botPassword}
            onChange={(e) => setBotPassword(e.target.value)}
          />
          <Button
            type="button"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleBotRegister}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Зарегистрировать пользователя'}
          </Button>
        </Box>

        {/* Смена пароля для выбранного пользователя */}
        <Box component="form" noValidate sx={{ mt: 4 }}>
          <Typography component="h3" variant="h6">
            Смена пароля пользователя
          </Typography>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="user-select-label-password">Выберите пользователя</InputLabel>
            <Select
              labelId="user-select-label-password"
              id="user-select-password"
              value={selectedUserForPassword}
              label="Выберите пользователя"
              onChange={(e) => setSelectedUserForPassword(e.target.value)}
            >
              {Array.isArray(allUsers) && allUsers.length > 0 ? (
                allUsers.map((user) => (
                  <MenuItem key={user.login} value={user.login}>
                    {user.name} ({user.login})
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>Нет доступных пользователей</MenuItem>
              )}
            </Select>
          </FormControl>
          <TextField
            margin="normal"
            required
            fullWidth
            name="new-password"
            label="Новый пароль"
            type="password"
            id="new-password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <Button
            type="button"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleChangePassword}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Изменить пароль'}
          </Button>
        </Box>

  {/* Удаление пользователя */}
        <Box sx={{ mt: 4 }}>
          <Typography component="h3" variant="h6">
            Удаление пользователя
          </Typography>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="user-select-label-delete-bot">Выберите пользователя</InputLabel>
            <Select
              labelId="user-select-label-delete-bot"
              id="user-select-delete-bot"
              value={selectedUserToDelete}
              label="Выберите пользователя"
              onChange={(e) => setSelectedUserToDelete(e.target.value)}
            >
              {Array.isArray(allUsers) && allUsers.length > 0 ? (
                allUsers.map((user) => (
                  <MenuItem key={user.login} value={user.login}>
                    {user.name} ({user.login})
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>Нет доступных пользователей</MenuItem>
              )}
            </Select>
          </FormControl>
          <Button
            type="button"
            fullWidth
            variant="outlined"
            color="error"
            sx={{ mt: 2 }}
            onClick={handleDeleteBotUser}
            disabled={loading || !selectedUserToDelete}
          >
            {loading ? <CircularProgress size={24} /> : 'Удалить пользователя'}
          </Button>
        </Box>

        {/* Просмотр всех пользователей */}
        <Box sx={{ mt: 4 }}>
          <Typography component="h3" variant="h6">
            Список пользователей
          </Typography>
          {Array.isArray(allUsers) && allUsers.length > 0 ? (
            <List>
              {allUsers.map((user) => (
                <React.Fragment key={user.login}>
                  <ListItem>
                    <ListItemText
                      primary={`Имя: ${user.name}, Логин: ${user.login}`}
                      secondary={`Стикеры: ${
                        Array.isArray(user.stickers) && user.stickers.length > 0
                          ? user.stickers.map((s) => s.text).join(', ')
                          : 'Нет стикеров'
                      }`}
                    />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          ) : (
            <Typography>Нет доступных пользователей.</Typography>
          )}
        </Box>

        {/* Добавление и удаление стикеров */}
        <Box sx={{ mt: 4 }}>
          <Typography component="h3" variant="h6">
            Управление стикерами
          </Typography>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="user-select-label-sticker">Выберите пользователя</InputLabel>
            <Select
              labelId="user-select-label-sticker"
              id="user-select-sticker"
              value={selectedUserForSticker}
              label="Выберите пользователя"
              onChange={(e) => setSelectedUserForSticker(e.target.value)}
            >
              {Array.isArray(allUsers) && allUsers.length > 0 ? (
                allUsers.map((user) => (
                  <MenuItem key={user.login} value={user.login}>
                    {user.name} ({user.login})
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>Нет доступных пользователей</MenuItem>
              )}
            </Select>
          </FormControl>
          <TextField
            margin="normal"
            required
            fullWidth
            id="sticker-text"
            label="Текст стикера"
            name="sticker-text"
            value={stickerText}
            onChange={(e) => setStickerText(e.target.value)}
          />
          <Button
            type="button"
            fullWidth
            variant="contained"
            sx={{ mt: 2 }}
            onClick={handleAddSticker}
            disabled={loading || !selectedUserForSticker}
          >
            {loading ? <CircularProgress size={24} /> : 'Добавить стикер'}
          </Button>

          {/* Отображение стикеров выбранного пользователя */}
          {selectedUserForSticker && (
            <Box sx={{ mt: 4 }}>
              <Typography component="h4" variant="subtitle1">
                Стикеры пользователя
              </Typography>
              {Array.isArray(
                allUsers.find((user) => user.login === selectedUserForSticker)?.stickers
              ) && allUsers.find((user) => user.login === selectedUserForSticker).stickers.length > 0 ? (
                <List>
                  {allUsers
                    .find((user) => user.login === selectedUserForSticker)
                    .stickers.map((sticker) => (
                      <ListItem key={sticker._id}>
                        <ListItemText primary={sticker.text} />
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => handleDeleteSticker(sticker._id)}
                          disabled={loading}
                        >
                          Удалить
                        </Button>
                      </ListItem>
                    ))}
                </List>
              ) : (
                <Typography>У пользователя нет стикеров.</Typography>
              )}
            </Box>
          )}
        </Box>

        {/* Отображение сообщений и ошибок */}
        {message && (
          <Typography color="success.main" sx={{ mt: 2 }}>
            {message}
          </Typography>
        )}
        {error && (
          <Typography color="error" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
      </Box>
    </Container>
  );
}

export default Admin;
