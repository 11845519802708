import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  Drawer,
  Typography,
  FormControlLabel,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Chip,
  IconButton,
  Snackbar,
  Alert,
  CircularProgress,
  Switch,
  useMediaQuery,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  FilterList as FilterListIcon,
  ClearAll as ClearAllIcon,
  Search as SearchIcon,
} from '@mui/icons-material';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const MapPage = () => {
  // Состояния для управления интерфейсом и данными
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [mapInitialized, setMapInitialized] = useState(false);
  const [stats, setStats] = useState({ total: 0, inProgress: 0 });
  const [error, setError] = useState(null);
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'info' });

  // Состояния для хранения данных
  const [projects, setProjects] = useState([]);
  const [boards, setBoards] = useState({});
  const [columns, setColumns] = useState({});
  const [tasks, setTasks] = useState([]); // Все задачи
  const [filteredData, setFilteredData] = useState([]); // Отфильтрованные данные
  const [displayedData, setDisplayedData] = useState([]); // Данные, отображаемые на карте после поиска

  // Состояния для фильтров
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedBoards, setSelectedBoards] = useState({});
  const [selectedColumns, setSelectedColumns] = useState({});
  const [selectedColors, setSelectedColors] = useState([]);
  const [availableColors, setAvailableColors] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchOptions, setSearchOptions] = useState([]); // Для хранения вариантов автозаполнения

  // Темы
  const [darkMode, setDarkMode] = useState(false);
  const [loading, setLoading] = useState(false);

  // Рефы для карты и кластеризатора
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const clustererRef = useRef(null);

  // Получение API-ключа из переменных окружения
  const yandexApiKey = process.env.REACT_APP_YANDEX_API_KEY;

  // Определение адаптивности для мобильных устройств
  const isMobile = useMediaQuery('(max-width:600px)');

  // Навигация
  const navigate = useNavigate();

  // Создание темы
  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
    },
  });

  // Состояния для администратора
  const [isAdmin, setIsAdmin] = useState(false);
  const [authChecked, setAuthChecked] = useState(false);

  // Состояния для списка объектов в кластере
  const [selectedClusterObjects, setSelectedClusterObjects] = useState([]);
  const [clusterListOpen, setClusterListOpen] = useState(false);

  // Функция для проверки статуса администратора
  const checkAdminStatus = async () => {
    try {
      const response = await axios.get('/api/auth/check-auth', { withCredentials: true });
      setIsAdmin(response.data.isAdmin);
      console.log('isAdmin:', response.data.isAdmin);
    } catch (error) {
      console.error('Ошибка проверки статуса администратора:', error);
      // Возможно, пользователь не авторизован или нет доступа к проверке
    } finally {
      setAuthChecked(true);
    }
  };

  // Загрузка Yandex Maps API
  const loadYmaps = () => {
    return new Promise((resolve, reject) => {
      if (window.ymaps) {
        console.log('Yandex Maps API уже загружен.');
        resolve();
      } else {
        console.log('Загрузка Yandex Maps API...');
        const script = document.createElement('script');
        script.src = `https://api-maps.yandex.ru/2.1/?apikey=${yandexApiKey}&lang=ru_RU`;
        script.async = true;
        script.onload = () => {
          console.log('Yandex Maps API загружен.');
          resolve();
        };
        script.onerror = () => {
          console.error('Не удалось загрузить Yandex Maps API.');
          reject(new Error('Yandex Maps API failed to load.'));
        };
        document.head.appendChild(script);
      }
    });
  };

  // Функция для добавления маркеров в пакетах
  const addPlacemarksInBatches = (data, clusterer, map, batchSize = 500) => {
    let index = 0;

    const addBatch = () => {
      const batch = data.slice(index, index + batchSize);
      const placemarks = batch
        .filter((item) => item.lat && item.lng)
        .map((item) => {
          const placemark = new window.ymaps.Placemark(
            [item.lat, item.lng],
            {
              balloonContent: `${item.projectTitle} / ${item.columnTitle} / ${item.title}`,
              data: item,
            },
            {
              preset: 'islands#icon',
              iconColor: item.color || '#FF0000',
            }
          );
          // Добавление обработчика клика для открытия балуна
          placemark.events.add('click', function () {
            placemark.balloon.open();
          });
          return placemark;
        });

      clusterer.add(placemarks);

      index += batchSize;

      if (index < data.length) {
        window.requestAnimationFrame(addBatch);
      } else {
        console.log('Все маркеры добавлены:', data.length);
        const bounds = clusterer.getBounds();
        if (bounds && bounds.length > 0) {
          map.setBounds(bounds, { checkZoomRange: true });
          console.log('Границы карты обновлены.');
        } else {
          console.warn('Не удалось получить границы кластеризатора. Устанавливаем центр вручную.');
          map.setCenter([55.751574, 37.573856], 5);
        }
        setLoading(false);
      }
    };

    addBatch();
  };

  // Функция для обновления маркеров в кластеризаторе
  const updatePlacemarks = (data) => {
    if (!clustererRef.current || !mapRef.current) return;

    clustererRef.current.removeAll();

    addPlacemarksInBatches(data, clustererRef.current, mapRef.current, 500);
  };

  // Инициализация карты с заданными данными
  const initMap = (data) => {
    console.log('initMap вызван с данными:', data.length, 'объектов');

    if (!window.ymaps) {
      console.error('ymaps не определен. Проверьте загрузку Yandex Maps API.');
      setError('Не удалось инициализировать карту. API Yandex Maps не загружен.');
      setNotification({ open: true, message: 'Не удалось загрузить карту. Попробуйте позже.', severity: 'error' });
      return;
    }

    if (!mapContainerRef.current) {
      console.error('Контейнер карты не найден.');
      setError('Не удалось найти контейнер для карты.');
      setNotification({ open: true, message: 'Не удалось найти контейнер для карты.', severity: 'error' });
      return;
    }

    try {
      if (!mapInitialized) {
        console.log('Инициализация карты...');

        const centerOfMap = [55.751574, 37.573856]; // Москва
        const map = new window.ymaps.Map(mapContainerRef.current, {
          center: centerOfMap,
          zoom: 5,
        });
        mapRef.current = map;
        console.log('Карта создана:', map);

        // Создание кластеризатора
        const clusterer = new window.ymaps.Clusterer({
          preset: 'islands#invertedVioletClusterIcons',
          groupByCoordinates: false,
          clusterDisableClickZoom: true,
          geoObjectOpenBalloonOnClick: true,
          clusterBalloonContentLayout: 'cluster#balloonAccordion',
          clusterBalloonItemContentLayout: window.ymaps.templateLayoutFactory.createClass(
            '<div>' +
              '<b>{{ properties.clusterCaption|raw }}</b>' +
              '<br/> {{ properties.balloonContent|raw }}' +
            '</div>'
          ),
          clusterOpenBalloonOnClick: true,
          clusterBalloonAccordionShowIcons: true,
        });
        clustererRef.current = clusterer;
        map.geoObjects.add(clusterer);
        console.log('Кластеризатор добавлен на карту:', clusterer);

        // Добавление маркеров в пакетах
        addPlacemarksInBatches(data, clusterer, map, 500);

        // Обработчик клика на кластер
        clusterer.events.add('click', function (e) {
          const target = e.get('target');
          if (target && target instanceof window.ymaps.Cluster) {
            // Получаем все геообъекты в кластере
            const geoObjects = target.getGeoObjects();

            // Извлекаем данные объектов
            const clusterData = geoObjects.map((obj) => obj.properties.get('data'));

            // Обновляем состояние для отображения списка
            setSelectedClusterObjects(clusterData);
            setClusterListOpen(true);

            console.log('Кластер содержит объектов:', clusterData.length);
          }
        });

        setMapInitialized(true);

        console.log('Карта инициализирована с новыми данными.');

        // Обновление статистики
        calculateStats(data);
      } else {
        console.log('Карта уже инициализирована. Обновление маркеров...');
        updatePlacemarks(data);
        calculateStats(data);
      }
    } catch (error) {
      console.error('Ошибка при инициализации карты:', error);
      setError('Произошла ошибка при инициализации карты.');
      setNotification({ open: true, message: 'Произошла ошибка при инициализации карты.', severity: 'error' });
    }
  };

  // Загрузка всех данных и инициализация карты
  const fetchAllDataAndInitMap = async () => {
    setLoading(true);
    try {
      console.log('Загрузка всех данных...');

      // Загрузка проектов
      console.log('Запрос проектов...');
      const projectsResponse = await axios.get('/api/projects');
      const projectsData = projectsResponse.data;
      setProjects(projectsData);
      console.log('Проекты загружены:', projectsData);

      // Параллельная загрузка досок для всех проектов
      console.log('Запрос досок для всех проектов...');
      const boardsPromises = projectsData.map((project) =>
        axios.get('/api/boards', { params: { projectId: project.id } })
      );
      const boardsResponses = await Promise.all(boardsPromises);
      const boardsData = {};
      projectsData.forEach((project, index) => {
        boardsData[project.id] = boardsResponses[index].data;
      });
      setBoards(boardsData);
      console.log('Доски загружены:', boardsData);

      // Параллельная загрузка колонок для всех досок
      console.log('Запрос колонок для всех досок...');
      const columnsPromises = [];
      for (const projectId in boardsData) {
        boardsData[projectId].forEach((board) => {
          columnsPromises.push(
            axios.get('/api/columns', { params: { boardId: board.id } })
          );
        });
      }
      const columnsResponses = await Promise.all(columnsPromises);
      const columnsData = {};
      let responseIndex = 0;
      for (const projectId in boardsData) {
        boardsData[projectId].forEach((board) => {
          columnsData[board.id] = columnsResponses[responseIndex].data;
          responseIndex++;
        });
      }
      setColumns(columnsData);
      console.log('Колонки загружены:', columnsData);

      // Загрузка задач
      console.log('Запрос задач...');
      const tasksResponse = await axios.get('/api/tasks');
      const tasksData = tasksResponse.data;

      // Обогащение задач дополнительными данными
      const projectsMap = {};
      projectsData.forEach((project) => {
        projectsMap[project.id] = project;
      });

      const columnsMap = {};
      Object.values(columnsData)
        .flat()
        .forEach((column) => {
          columnsMap[column.id] = column;
        });

      const enrichedTasks = tasksData.map((task) => {
        return {
          ...task,
          projectTitle: projectsMap[task.projectId]?.title || '',
          columnTitle: columnsMap[task.columnId]?.title || '',
        };
      });

      setTasks(enrichedTasks);
      setFilteredData(enrichedTasks);
      setDisplayedData(enrichedTasks); // Изначально отображаем все данные
      console.log('Задачи загружены и обогащены. Количество задач:', enrichedTasks.length);

      // Извлечение уникальных цветов
      const uniqueColors = [...new Set(enrichedTasks.map((item) => item.color).filter(Boolean))];
      setAvailableColors(uniqueColors);
      console.log('Доступные цвета:', uniqueColors);

      // Извлечение уникальных названий для поиска
      const uniqueTitles = [...new Set(enrichedTasks.map((item) => item.title).filter(Boolean))];
      setSearchOptions(uniqueTitles);

      // Загрузка Yandex Maps и инициализация карты с загруженными данными
      console.log('Загрузка Yandex Maps API...');
      await loadYmaps();
      console.log('Проверка доступности ymaps после загрузки...');
      if (window.ymaps && mapContainerRef.current) {
        console.log('Инициализация карты после загрузки Yandex Maps API.');
        window.ymaps.ready(() => {
          console.log('ymaps.ready вызван');
          initMap(enrichedTasks);
        });
      } else {
        throw new Error('ymaps или mapContainerRef.current недоступны после загрузки API.');
      }

      // Проверка статуса администратора
      await checkAdminStatus();
    } catch (error) {
      console.error('Ошибка при загрузке данных или инициализации карты:', error);
      setError('Произошла ошибка при загрузке данных или инициализации карты.');
      setNotification({ open: true, message: 'Произошла ошибка при загрузке данных.', severity: 'error' });
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllDataAndInitMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Обновление карты при изменении displayedData
  useEffect(() => {
    if (mapInitialized) {
      console.log('Карта инициализирована. Обновление маркеров...');
      initMap(displayedData);
      if (displayedData.length > 0) {
        // Дополнительные действия при наличии данных
      } else {
        console.log('Нет данных для отображения на карте.');
        setNotification({ open: true, message: 'Нет данных для отображения на карте.', severity: 'info' });
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayedData]);

  // Обработчик для обновления displayedData при изменении searchQuery
  useEffect(() => {
    const timer = setTimeout(() => {
      const filtered = filteredData.filter((task) =>
        searchQuery.trim() === '' ||
        task.title.toLowerCase().includes(searchQuery.trim().toLowerCase())
      );
      setDisplayedData(filtered);
    }, 500);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, filteredData]);

  // Функция для загрузки маркеров с учётом выбранных фильтров
  const loadMarkersWithFilters = () => {
    console.log('Загрузка маркеров с учётом фильтров...');
    setLoading(true);

    const selectedProjectIds = new Set(selectedProjects);
    const selectedBoardIds = new Set(Object.values(selectedBoards).flat());
    const selectedColumnIds = new Set(Object.values(selectedColumns).flat());

    console.log('Выбранные проекты:', Array.from(selectedProjectIds));
    console.log('Выбранные доски:', Array.from(selectedBoardIds));
    console.log('Выбранные колонки:', Array.from(selectedColumnIds));
    console.log('Выбранные цвета:', selectedColors);
    console.log('Поисковый запрос:', searchQuery);

    const uniqueTasks = [];
    const taskIds = new Set();

    tasks.forEach((task) => {
      const matchesProject = selectedProjectIds.size === 0 || selectedProjectIds.has(task.projectId);
      const matchesBoard = selectedBoardIds.size === 0 || selectedBoardIds.has(task.boardId);
      const matchesColumn = selectedColumnIds.size === 0 || selectedColumnIds.has(task.columnId);
      const matchesColor = selectedColors.length === 0 || selectedColors.includes(task.color);

      if ((matchesProject || matchesBoard || matchesColumn) && matchesColor) {
        if (!taskIds.has(task.id)) {
          uniqueTasks.push(task);
          taskIds.add(task.id);
        }
      }
    });

    setFilteredData(uniqueTasks);
    console.log('Всего отфильтрованных задач:', uniqueTasks.length);

    // Обновляем варианты поиска на основе отфильтрованных данных
    const uniqueTitles = [...new Set(uniqueTasks.map((item) => item.title).filter(Boolean))];
    setSearchOptions(uniqueTitles);

    // Применяем поиск к отфильтрованным данным
    const displayedTasks = uniqueTasks.filter((task) =>
      searchQuery.trim() === '' ||
      task.title.toLowerCase().includes(searchQuery.trim().toLowerCase())
    );

    setDisplayedData(displayedTasks);
    console.log('Всего задач после поиска:', displayedTasks.length);

    setNotification({ open: true, message: `Найдено ${displayedTasks.length} задач.`, severity: 'success' });
    setLoading(false);
  };

  // Функция для расчёта статистики
  const calculateStats = (tasks) => {
    console.log('Расчёт статистики для задач:', tasks.length);
    const total = tasks.length;
    const inProgress = tasks.filter((task) => task.columnTitle === 'В РАБОТУ').length;
    setStats({ total, inProgress });
    console.log('Статистика обновлена:', { total, inProgress });
  };

  // Обработчики для фильтров

  const handleSelectAll = () => {
    console.log('Выбор всех проектов, досок, колонок и цветов.');
    const allProjectIds = projects.map((p) => p.id);
    setSelectedProjects(allProjectIds);
    console.log('Выбранные проекты:', allProjectIds);

    const allBoards = {};
    for (const projectId in boards) {
      allBoards[projectId] = (boards[projectId] || []).map((b) => b.id);
    }
    setSelectedBoards(allBoards);
    console.log('Выбранные доски:', allBoards);

    const allColumns = {};
    for (const boardId in columns) {
      allColumns[boardId] = (columns[boardId] || []).map((c) => c.id);
    }
    setSelectedColumns(allColumns);
    console.log('Выбранные колонки:', allColumns);

    setSelectedColors(availableColors);
    console.log('Выбранные цвета:', availableColors);
  };

  const handleDeselectAll = () => {
    console.log('Снятие выбора со всех проектов, досок, колонок и цветов.');
    setSelectedProjects([]);
    console.log('Выбранные проекты:', []);

    const emptyBoards = {};
    for (const projectId in boards) {
      emptyBoards[projectId] = [];
    }
    setSelectedBoards(emptyBoards);
    console.log('Выбранные доски:', emptyBoards);

    const emptyColumns = {};
    for (const boardId in columns) {
      emptyColumns[boardId] = [];
    }
    setSelectedColumns(emptyColumns);
    console.log('Выбранные колонки:', emptyColumns);

    setSelectedColors([]);
    console.log('Выбранные цвета:', []);
  };

  // Обработчик для закрытия уведомлений
  const handleCloseNotification = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  // Обработчик переключения темы
  const handleToggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  // Обработчик выхода из системы
  const handleLogout = async () => {
    try {
      await axios.post('/api/auth/logout', {}, { withCredentials: true });
      navigate('/login');
    } catch (error) {
      console.error('Ошибка выхода:', error);
      setError('Ошибка выхода. Пожалуйста, попробуйте снова.');
    }
  };

  // Обработчик перехода в админ-панель
  const goToAdminPanel = () => {
    navigate('/admin');
  };

  // Вычисление состояния чекбокса "Выбрать все"
  const isAllSelected =
    selectedProjects.length === projects.length &&
    Object.keys(boards).every(
      (projectId) => selectedBoards[projectId]?.length === (boards[projectId]?.length || 0)
    ) &&
    Object.keys(columns).every(
      (boardId) => selectedColumns[boardId]?.length === (columns[boardId]?.length || 0)
    ) &&
    selectedColors.length === availableColors.length;

  const isIndeterminate =
    (selectedProjects.length > 0 && selectedProjects.length < projects.length) ||
    Object.keys(boards).some(
      (projectId) =>
        (selectedBoards[projectId]?.length || 0) > 0 &&
        (selectedBoards[projectId]?.length || 0) < (boards[projectId]?.length || 0)
    ) ||
    Object.keys(columns).some(
      (boardId) =>
        (selectedColumns[boardId]?.length || 0) > 0 &&
        (selectedColumns[boardId]?.length || 0) < (columns[boardId]?.length || 0)
    ) ||
    (selectedColors.length > 0 && selectedColors.length < availableColors.length);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ height: '100vh', display: 'flex', width: '100vw' }}>
        {/* Поисковая строка с автозаполнением посередине сверху */}
        <Box
          sx={{
            position: 'absolute',
            top: 16,
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1000,
            width: isMobile ? '80%' : '40%',
          }}
        >
          <Autocomplete
            freeSolo
            options={searchOptions}
            inputValue={searchQuery}
            onInputChange={(event, newInputValue) => {
              setSearchQuery(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Поиск по названию"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <SearchIcon color="action" style={{ marginRight: 8 }} />
                  ),
                  endAdornment: searchQuery && (
                    <IconButton
                      onClick={() => {
                        setSearchQuery('');
                      }}
                      aria-label="Очистить"
                    >
                      <ClearAllIcon />
                    </IconButton>
                  ),
                }}
              />
            )}
          />
        </Box>

        {/* Верхняя панель с кнопками фильтров, админ-панелью и выходом */}
        <Box
          sx={{
            position: 'absolute',
            bottom: 16,
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1000,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {/* Кнопка для открытия панели фильтров */}
          <Button
            onClick={() => setDrawerOpen(true)}
            className="filter-panel"
            variant="contained"
            startIcon={<FilterListIcon />}
            sx={{
              textTransform: 'none',
              mb: 2,
            }}
          >
            Фильтры
          </Button>
          <Button variant="contained" color="primary" onClick={handleLogout} sx={{ mb: 2 }}>
            Выйти
          </Button>
          {authChecked && isAdmin && (
            <Button
              variant="contained"
              color="secondary"
              onClick={goToAdminPanel}
              sx={{ mb: 2 }}
            >
              Админ панель
            </Button>
          )}

          {/* Переключатель темы */}
          <Box display="flex" alignItems="center">
            <Typography variant="body2" mr={1}>
              Темная тема
            </Typography>
            <Switch checked={darkMode} onChange={handleToggleDarkMode} />
          </Box>
        </Box>
        {/* Панель фильтров */}
        <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
          <Box p={3} width={isMobile ? '100vw' : 400} role="presentation">
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <Typography variant="h6">Фильтры</Typography>
              <IconButton onClick={() => setDrawerOpen(false)}>
                <ExpandLessIcon />
              </IconButton>
            </Box>

            {/* Чекбокс "Выбрать все" */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={isAllSelected}
                  indeterminate={isIndeterminate}
                  onChange={(e) => {
                    if (e.target.checked) {
                      handleSelectAll();
                    } else {
                      handleDeselectAll();
                    }
                  }}
                />
              }
              label="Выбрать все"
            />

            {/* Кнопка сброса фильтров */}
            <Box display="flex" justifyContent="center" mb={2}>
              <Button
                onClick={() => {
                  // Сброс фильтров
                  handleDeselectAll();
                }}
                variant="text"
                color="secondary"
                startIcon={<ClearAllIcon />}
              >
                Сбросить фильтры
              </Button>
            </Box>

            {/* Фильтр по цветам с кружками цветов */}
            <Box mb={2}>
              <Typography variant="body2" gutterBottom>
                Цвета
              </Typography>
              <Autocomplete
                multiple
                options={availableColors}
                getOptionLabel={(option) => option}
                value={selectedColors}
                onChange={(event, newValue) => {
                  setSelectedColors(newValue);
                }}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Box
                      sx={{
                        width: 20,
                        height: 20,
                        backgroundColor: option,
                        borderRadius: '50%',
                        marginRight: 1,
                        border: '1px solid #ccc',
                      }}
                    />
                    {option}
                  </li>
                )}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      label={option}
                      {...getTagProps({ index })}
                      key={option}
                      avatar={
                        <Box
                          sx={{
                            width: 20,
                            height: 20,
                            backgroundColor: option,
                            borderRadius: '50%',
                            border: '1px solid #ccc',
                          }}
                        />
                      }
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" placeholder="Выберите цвета" />
                )}
                size="small"
              />
            </Box>

            {/* Фильтр по проектам, доскам и колонкам */}
            {projects.map((project) => (
              <Accordion key={project.id} defaultExpanded={false} sx={{ mb: 1 }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel-project-${project.id}-content`}
                  id={`panel-project-${project.id}-header`}
                >
                  <Typography variant="subtitle1">{project.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {/* Фильтр по доскам проекта */}
                  <Box mb={2}>
                    <Typography variant="body2" gutterBottom>
                      Доски
                    </Typography>
                    <Autocomplete
                      multiple
                      options={boards[project.id] || []}
                      getOptionLabel={(option) => option.title}
                      value={
                        boards[project.id]
                          ? selectedBoards[project.id]?.map((id) =>
                              (boards[project.id] || []).find((b) => b.id === id)
                            ).filter(Boolean)
                          : []
                      }
                      onChange={(event, newValue) => {
                        setSelectedBoards((prevSelectedBoards) => ({
                          ...prevSelectedBoards,
                          [project.id]: newValue.map((b) => b.id),
                        }));
                      }}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip label={option.title} {...getTagProps({ index })} key={option.id} />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" placeholder="Выберите доски" />
                      )}
                      size="small"
                    />
                  </Box>

                  {/* Фильтр по колонкам доски проекта */}
                  {(boards[project.id] || []).map((board) => (
                    <Accordion key={board.id} defaultExpanded={false} sx={{ mb: 1 }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel-board-${board.id}-content`}
                        id={`panel-board-${board.id}-header`}
                      >
                        <Typography variant="body2">{board.title}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography variant="caption" display="block" gutterBottom>
                          Колонки
                        </Typography>
                        <Autocomplete
                          multiple
                          options={columns[board.id] || []}
                          getOptionLabel={(option) => option.title}
                          value={
                            columns[board.id]
                              ? selectedColumns[board.id]?.map((id) =>
                                  (columns[board.id] || []).find((c) => c.id === id)
                                ).filter(Boolean)
                              : []
                          }
                          onChange={(event, newValue) => {
                            setSelectedColumns((prevSelectedColumns) => ({
                              ...prevSelectedColumns,
                              [board.id]: newValue.map((c) => c.id),
                            }));
                          }}
                          renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                              <Chip label={option.title} {...getTagProps({ index })} key={option.id} />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" placeholder="Выберите колонки" />
                          )}
                          size="small"
                        />
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}

            {/* Кнопка для применения фильтров */}
            <Button
              onClick={() => {
                loadMarkersWithFilters();
                setDrawerOpen(false);
              }}
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 3 }}
              startIcon={<FilterListIcon />}
              disabled={loading}
            >
              Применить фильтры
            </Button>

            {/* Показатель загрузки */}
            {loading && (
              <Box display="flex" justifyContent="center" mt={2}>
                <CircularProgress />
              </Box>
            )}

            {/* Отображение статистики */}
            <Box mt={4}>
              <Typography variant="h6" gutterBottom>
                Статистика:
              </Typography>
              <Typography>Всего объектов на карте: {stats.total}</Typography>
              <Typography>Задач "В работе": {stats.inProgress}</Typography>
            </Box>
          </Box>
        </Drawer>

        {/* Контейнер для карты */}
        <div
          ref={mapContainerRef}
          style={{
            height: '100vh',
            width: '100vw',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1,
          }}
        ></div>

        {/* Уведомления */}
        <Snackbar
          open={notification.open}
          autoHideDuration={6000}
          onClose={handleCloseNotification}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseNotification} severity={notification.severity} sx={{ width: '100%' }}>
            {notification.message}
          </Alert>
        </Snackbar>

        {/* Отображение ошибок (если есть) */}
        {error && (
          <Snackbar
            open={!!error}
            autoHideDuration={6000}
            onClose={() => setError(null)}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>
        )}

        {/* Drawer для отображения списка объектов в кластере */}
        <Drawer
          anchor="left"
          open={clusterListOpen}
          onClose={() => setClusterListOpen(false)}
        >
          <Box
            p={3}
            width={isMobile ? '100vw' : 300}
            role="presentation"
            sx={{
              backgroundColor: 'background.paper',
              height: '100%',
            }}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <Typography variant="h6">Список объектов в кластере</Typography>
              <IconButton onClick={() => setClusterListOpen(false)}>
                <ExpandLessIcon />
              </IconButton>
            </Box>

            {/* Список объектов */}
            <List>
              {selectedClusterObjects.map((obj, index) => (
                <ListItem key={index} divider>
                  <ListItemText
                    primary={`${index + 1}. ${obj.projectTitle} / ${obj.columnTitle} / ${obj.title}`}
                    secondary={obj.address || ''}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
      </Box>
    </ThemeProvider>
  );
};

export default MapPage;
