import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';

const PrivateRoute = ({ children, adminOnly = false }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
  const checkAuth = async () => {
    try {
console.log('Текущие куки в браузере:', document.cookie);
      console.log('Отправка запроса на проверку аутентификации...');
      const response = await axios.get('https://mapyg.ru/server/check-auth', { withCredentials: true });
      
      console.log('Ответ от /check-auth:', response.data); // Логирование ответа
      
      setAuthenticated(response.data.authenticated);
      setIsAdmin(response.data.isAdmin);
    } catch (error) {
      console.error('Authentication check failed', error);
    } finally {
      setLoading(false);
    }
  };
  checkAuth();
}, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  // If route is admin only and user is not an admin, redirect to unauthorized or map
  if (adminOnly && !isAdmin) {
    return <Navigate to="/login" />;
  }

  return authenticated ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
