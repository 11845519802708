import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Typography, Box, Container } from '@mui/material';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(''); // Состояние для хранения ошибки
  const navigate = useNavigate();

  const handleLogin = async () => {
    console.log('Attempting login with:', username, password); // Логирование введенных данных
    setError(''); // Сброс ошибки перед новой попыткой входа
    try {
      const response = await axios.post('https://mapyg.ru/server/login', { username, password }, { withCredentials: true });
      if (response.data.isAdmin) {
        navigate('/admin');
      } else {
        navigate('/map');
      }
    } catch (error) {
      if (error.response) {
        // Обработка различных сообщений об ошибках
        setError(error.response.data); // Устанавливаем сообщение об ошибке от сервера
      } else {
        setError('Ошибка сети. Попробуйте позже.'); // Сообщение об ошибке сети
      }
      console.error('Invalid credentials', error);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h2">
          MapYG
        </Typography>
        <Typography component="h2" variant="h5">
          Войти
        </Typography>
        <Box component="form" onSubmit={(e) => { e.preventDefault(); handleLogin(); }} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Имя Пользователя"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Пароль"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {error && <Typography color="error">{error}</Typography>} {/* Отображение сообщения об ошибке */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Войти
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default Login;
